/*--------------------------------------------------------------
	#contact-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#5.1	contact-section
--------------------------------------------------------------*/
.contact-section {
	padding-bottom: 40px;

	@include media-query(991px) {
		padding-bottom: 20px;
	}

	.contact-info-grids {
		text-align: center;

		> div {
			background-color: $theme-primary-color;
			width: 25%;
			float: left;
			padding: 60px 35px;
			position: relative;

			@include media-query(991px) {
				width: 50%;
				padding: 60px 35px 40px;
			}

			@include media-query(767px) {
				width: 100%;
				padding: 40px 25px;
				border-left: 0;
				float: none;
			}
		}

		> div:nth-child(2),
		> div:nth-child(4) {
			background-color: darken($theme-primary-color, 3%);;
		}

		.fi:before {
			font-size: 50px;
			font-size: calc-rem-value(50);
			color: $theme-primary-color;

			@include media-query(991px) {
				font-size: 40px;
				font-size: calc-rem-value(40);
			}

			@include media-query(767px) {
				font-size: 35px;
				font-size: calc-rem-value(35);
			}
		}

		h4 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin: 1em 0 0.8em;
		}

		p {
			margin: 0;
		}

		h4,
		p,
		.fi:before {
			color: $white;
		}
	}

	.contact-form-area {
		margin-top: 110px;

		@include media-query(991px) {
			margin-top: 70px;
		}
	}

	.contact-text {
		@include media-query(991px) {
			margin-bottom: 40px;
		}

		h3 {
			font-size: 36px;
			font-size: calc-rem-value(36);
			margin: -0.2em 0 0.4em;

			@include media-query(991px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
			}

			@include media-query(767px) {
				font-size: 25px;
				font-size: calc-rem-value(25);
			}
		}
	}

	form input,
	form select,
	form textarea {
		background: $white;
		width: 100%;
		height: 55px;
		border: 2px solid #d4d9e0;
		border-radius: 0;
		box-shadow: none !important;

		@include media-query(991px) {
			height: 45px;
		}

		&:focus {
			border-color: $theme-primary-color;
		}
	}

	form textarea {
		height: 180px;
	}

	form {
		margin: 0 -15px;
    	overflow: hidden;

		@include placeholder-style(#9d9c9c, 15px, normal);

	    select {
	        display: inline-block;
	        color: #a9a9a9;
	        cursor:pointer;
	        opacity: 1;
	        padding: 6px 12px;
	        font-size: 15px;
	        font-size: calc-rem-value(15);
	    }
	}

	form > div {
		width: calc(50% - 30px);
	    float: left;
	    margin: 0 15px 30px;

	    @include media-query(600px) {
			width: calc(100% - 30px);
	    	float: none;
		}
	}

	form .fullwidth {
		width: calc(100% - 30px);
		float: none;
		clear: both;
	}

	form button {
		outline: none;
		border: 0;
	}

}

.contact-map-section {

	.contact-map {
	    height: 450px;
	}

    iframe {
	    width: 100%;
	    height: 100%;
	    border: 0;
	    outline: 0;
	}
}