/*------------------------------------
	blog sidebar
------------------------------------*/
.blog-sidebar {
	@include widther(1200px) {
		padding-left: 45px;
	}

	@include media-query(991px) {
		margin-top: 80px;
		max-width: 400px;
	}

	@include media-query(767px) {
		margin-top: 60px;
		max-width: 400px;
	}

	.widget {
		border: 2px solid #f4f4f4;
		padding: 32px 25px;

		h3 {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: 400;
			margin: 0 0 1.3em;
			position: relative;
			text-transform: capitalize;
		}
	}

	> .widget + .widget {
		margin-top: 65px;
	}


	/*** search-widget ***/
	.search-widget {
		form div {
			position: relative;
		}

		input {
			background-color: #f8f8f8;
			height: 50px;
			font-size: 16px;
			font-size: calc-rem-value(16);
			padding: 6px 50px 6px 20px;
			border: 0;
			border-radius: 0;
			box-shadow: none;
			@include transition-time(0.3s);
		}

		input:focus {
			background-color: transparentize($theme-primary-color, 0.9);
		}

		form button {
			background: $theme-primary-color;
			width: 50px;
			height: 50px;
			line-height: 50px;
			font-size: 20px;
			font-size: calc-rem-value(20);
			color: $white;
			border: 0;
			outline: 0;
			position: absolute;
			right: 0;
			top: 0;
			border-radius: 0;

			i {
				font-size: 14px;
			}
		}
	}


	/*** category-widget ***/
	.category-widget {
		ul {
			list-style: none;
		}

		ul li {
			font-size: 15px;
			font-size: calc-rem-value(15);
			position: relative;

			@include media-query(767px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}

			span {
				position: absolute;
				right: 0;
			}
		}

		ul > li + li {
			margin-top: 12px;
			padding-top: 12px;
			border-top: 1px solid #f3f2f2;
		}

		ul a {
			display: block;
			color: #717171;
			padding-left: 25px;
			position: relative;

			&:before {
				font-family: "Themify";
				content: "\e628";
				position: absolute;
				left: 0;
				top: 2px;
				font-size: 11px;
			}
		}

		ul a:hover,
		ul li:hover:before {
			color: $theme-primary-color;
		}
	}


	/*** recent-post-widget ***/
	.recent-post-widget {
		.post {
			overflow: hidden;
		}

		.posts > .post + .post {
			margin-top: 15px;
			padding-top: 15px;
			border-top: 1px solid #f3f2f2;
		}

		.post .img-holder {
			width: 68px;
			float: left;

			img {
				border-radius: 5px;
			}
		}

		.post .details {
			width: calc(100% - 68px);
			float: left;
			padding-left: 20px;
		}

		.post h4 {
			font-family: $base-font;
			font-size: 14px;
			font-size: calc-rem-value(14);
			line-height: 1.3em;
			margin: 0 0 0.3em;

			@include media-query(1199px) {
				margin: 0;
			}
		}

		.post h4 a {
			font-weight: 600;
			display: inline-block;
			color: $heading-color;
		}

		.post h4 a:hover {
			color: $theme-primary-color;
		}

		.post .details .date {
			font-size: 13px;
			font-size: calc-rem-value(13);
			color: #687693;

			i {
				display: inline-block;
				padding-right: 7px;
			}
		}
	}


	/*** tag-widget ***/
	.tag-widget {

		ul {
			overflow: hidden;
			list-style: none;
		}

		ul li {
			float: left;
			margin: 0 8px 8px 0;
		}

		ul li a {
			font-size: 13px;
			font-size: calc-rem-value(13);
			font-weight: 600;
			display: inline-block;
			padding: 7px 14px;
			color: #717171;
			border: 1px solid #efefef;
			border-radius: 50px;
		}

		ul li a:hover {
			background: $theme-primary-color;
			color: $white;
			border-color: $theme-primary-color;
		}
	}
}